$aos-distance: 50px;

$enable-responsive-font-sizes: false;
$rfs-rem-value: 16;

//$primary: map-get($colors, 'blue');//#2e79bd;

//$grid-columns:      12;
$grid-gutter-width: 20px;
$grid-gutter-width-mobile: calc(#{$grid-gutter-width} + 10px);

$grid-breakpoints: (
        xxs: 0,
        xs: 480px,
        sm: 680px,
        md: 1024px,
        lg: 1280px,
        xl: 1400px,
        xxl: 1680px,
        rt: 2200px,
        rt15: 2880px,
        uhd: 3840px,
        4k: 4200px,
        8k: 6000px
);

$container-max-widths: (
        md: 980px,
        lg: 1130px,
        xl: 1200px,
        xxl: 1300px,
        rt: 1480px,
        rt15: 1920px,
        uhd: 2820px,
        4k: 3240px,
        8k: 4036px
);

$container-middle-max-widths: (
        lg: 1180px,
        xl: 1280px,
        xxl: 1500px,
        rt: 1750px,
        rt15: 2250px,
        uhd: 3200px,
        4k: 3840px,
        8k: 5036px
);

$table-head-bg: lighten(map-get($colors,'light-grey'),10%);

$h1-font-size: 2em;//2.5
$h2-font-size: 1.5em;//2
$h3-font-size: 1.17em;//1.75
$h4-font-size: 1em;//1.5
$h5-font-size: .83em;//1.25
$h6-font-size: .65em;//1

$headings-line-height: inherit;
$headings-font-weight: 700;

//$link-color: $main_color;
$pagination-color: $main_color;
$pagination-focus-color: $main_color;
$pagination-hover-color: $main_color;

//$pagination-active-color:           $component-active-color;
$pagination-active-bg: $main_color;
$pagination-active-border-color: $main_color;

$table-bg: rgba(255,255,255,0);

$modal-backdrop-bg: #000;
$modal-backdrop-opacity: .5;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 9,
  8: $spacer * 12,
)
